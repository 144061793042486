import React from "react"
import Navbar from "../components/Navbar"

import { Link } from "gatsby"

const NotFound = () => {
  return (
    <>
      <Navbar />

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="Not-found">
              <h1>404</h1>
              <h3>Page Not Found</h3>
              <Link to="/">Go back to Homepage</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound
